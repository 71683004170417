export const langPrefix = lang => (lang !== "en" ? "/" + lang + "/" : "/");

export const getImgUrl = (imgName, list) =>
  list.edges &&
  list.edges.filter(i => i.node.name === imgName).map(i => i.node.publicURL)[0];

export const getImagePath = (imgName, folderName) =>
  "/static/src/images/" + folderName + "/" + imgName + ".png";

export const head = array => {
  return array != null && array.length ? array[0] : undefined;
};
