const languages = ["en", "dk", "es", "de", "fr"];
const appLanguage = languages[0];

const pageElements = {
  en: {
    nav: [
      { name: "Home page", link: "" },
      { name: "About us", link: "about" },
      { name: "Projects", link: "case-studies" },
      { name: "Products", link: "products" },
      { name: "Blog", link: "blog" },
      { name: "Contact", link: "contact" }
    ],
    cookies: {
      text: "Our website uses cookies.",
      readMore: "Read more",
      button: "Accept Cookies"
    }
  },
  dk: {
    nav: [
      { name: "Home", link: "" },
      { name: "Om os", link: "about" },
      { name: "Case studies", link: "case-studies" },
      { name: "Produkter", link: "products" },
      { name: "Blog", link: "blog" },
      { name: "Kontakt os", link: "contact" }
    ],
    cookies: {
      text: "Vorse hjemmeside bruger cookies.",
      readMore: "Læs mere",
      button: "Accepter Cookies"
    }
  },
  es: {
    nav: [
      { name: "Inicio", link: "" },
      { name: "Acerca de Fastpack", link: "about" },
      { name: "Proyectos", link: "case-studies" },
      { name: "Products", link: "products" },
      { name: "Blog", link: "blog" },
      { name: "Contacto", link: "contact" }
    ],
    cookies: {
      text: "Our website uses cookies.",
      readMore: "Read more",
      button: "Accept Cookies"
    }
  },
  de: {
    nav: [
      { name: "Homeseite", link: "" },
      { name: "Über uns", link: "about" },
      { name: "Unsere Projekte", link: "case-studies" },
      { name: "Products", link: "products" },
      { name: "Blog", link: "blog" },
      { name: "Kontakt", link: "contact" }
    ],
    cookies: {
      text: "Our website uses cookies.",
      readMore: "Read more",
      button: "Accept Cookies"
    }
  },
  fr: {
    nav: [
      { name: "Page d'accueil", link: "" },
      { name: "À propos de nous", link: "about" },
      { name: "Nos projets", link: "case-studies" },
      { name: "Products", link: "products" },
      { name: "Blog", link: "blog" },
      { name: "Contact", link: "contact" }
    ],
    cookies: {
      text: "Our website uses cookies.",
      readMore: "Read more",
      button: "Accept Cookies"
    }
  }
};

export { languages, pageElements, appLanguage };
