import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Image } from "react-bootstrap";
import { getImgUrl } from "../../../shared/scripts";
import styles from "./Flags.module.css";

const Flags = ({ lang }) => {
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: { fields: name, order: DESC }
          filter: { relativeDirectory: { eq: "flags" } }
        ) {
          edges {
            node {
              id
              name
              publicURL
            }
          }
        }
      }
    `
  );
  return (
    <div className={styles.self}>
      <Image src={getImgUrl(lang, allFile)} />
    </div>
  );
};

export default Flags;
// width: 1.25rem;
//     margin-right: 0.5rem;
