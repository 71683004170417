import React, { useState, useEffect, useContext } from "react";
import { Navbar, Image, Nav } from "react-bootstrap";
import styles from "./Header.module.css";
import classnames from "classnames";
import { languages, pageElements } from "../../shared/pageElements";
import { Link } from "gatsby";
import {
  GlobalDispatchContext,
  GlobalStateContext
} from "../../context/GlobalContextProvider";
import { langPrefix } from "../../shared/scripts";
import {
  CHANGE_LANGUAGE,
  CHANGE_PAGE,
  HOME_PAGE_CLICKED_PRODUCT
} from "../../types";
import Flags from "./Flags";
import { graphql, useStaticQuery } from "gatsby";

const GenerateLanguageSelector = ({ languages, selected, change, page }) => {
  const isHome = page === "home";
  return languages
    .filter(l => l !== selected)
    .map(lang => (
      <Link
        className={classnames(styles.link, styles.langs)}
        key={`main-lang-${lang}`}
        onClick={() => {
          change(lang);
        }}
        to={`${langPrefix(lang)}${isHome ? "/" : page}`}
      >
        <Flags lang={lang} /> {lang}
      </Link>
    ));
};

const Header = () => {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const [sticky, setSticky] = useState(false);
  const lang = state.language || "en";
  const { nav } = pageElements[lang];
  const { file } = useStaticQuery(
    graphql`
      query {
        file(name: { eq: "fastpack_logo_www" }) {
          name
          publicURL
        }
      }
    `
  );
  const onLanguageChange = lang => {
    dispatch({ type: CHANGE_LANGUAGE, payload: lang });
  };
  const onPageChange = (e, page) => {
    dispatch({ type: CHANGE_PAGE, payload: page });
  };
  const onSubProductPageChange = page => {
    dispatch({ type: HOME_PAGE_CLICKED_PRODUCT, payload: page });
  };
  const generateNavigation = () =>
    nav.map(e => (
      <Link
        className={styles.link}
        key={`main-nav-${e.link}`}
        onClick={event => {
          onPageChange(event, e.link);
        }}
        to={`${langPrefix(lang)}${e.link}`}
        activeClassName={styles.active}
      >
        {e.name}
      </Link>
    ));

  return (
    <Navbar
      expand="lg"
      className={classnames(styles.self, { [styles.sticky]: sticky })}
    >
      <header id="navbar" className="container">
        <Navbar.Brand className={styles.logo}>
          <Link to={langPrefix(lang)}>
            <Image src={file.publicURL} />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={styles.nav}>{generateNavigation()}</Nav>

          {languages && (
            <Nav className={styles.lang}>
              <GenerateLanguageSelector
                page={state.page}
                change={onLanguageChange}
                languages={languages}
                selected={lang}
              />
            </Nav>
          )}
        </Navbar.Collapse>
      </header>
    </Navbar>
  );
};

export default Header;
